.simplebar-scrollbar {
  &::before {
    background: #edf0f4;
    border-radius: 3px;
    opacity: 1 !important;
  }
}

.simplebar-hover {
  .simplebar-scrollbar::before {
    background: #e4e4e4;
  }
}

