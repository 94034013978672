body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
}

:root {
  --amplify-primary-color: #02aed6;
  --amplify-primary-tint: #02aed6;
  --amplify-primary-shade: #02aed6;
  --amplify-components-button-primary-background-color: #02aed6;
  --amplify-components-tabs-item-active-border-color: #02aed6;
  --amplify-components-tabs-item-active-color: #02aed6;
  --amplify-components-fieldcontrol-focus-border-color: #02aed6;
  --amplify-components-fieldcontrol-focus-box-shadow: transparent;
}

[data-amplify-authenticator] {
  display: flex;
  margin: 0 auto;
}

.amplify-alert__body {
  white-space: break-spaces;
}
