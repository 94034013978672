/**
 * Derived from the example in
 * https://github.com/airbnb/react-dates/tree/b66dbe4397c7307946abecb10bf37914ebc746f9#overriding-styles
 *
 * TODO: migrate to CSS-in-JS
 */
.DayPicker {
  margin: -22px -22px -16px;
}

// The picker doesn't have initial height, so when we open it (for DateRangeControl, it's inside
// Popper), most of the time it's partially out of viewport. More or less related issues:
// https://github.com/airbnb/react-dates/issues/763#issuecomment-378601772
// https://github.com/airbnb/react-dates/issues/935
.DayPicker_transitionContainer {
	min-height: 300px;
}

.CalendarDay {
  border: none !important;

  .CalendarDay__content {
    width: 100%;
    height: 100%;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  &:hover {
    .CalendarDay__content {
      background-color: rgba(0, 0, 0, 0.08);
    }
  }

  &[aria-disabled=true]:hover {
    .CalendarDay__content {
      background-color: transparent;
    }
  }
}

.CalendarDay__default {
  &:hover {
    background: #fff;
  }
}

// часть выбранного (selected) или выбираемого (hovered) промежутка
.CalendarDay__selected_span, .CalendarDay__hovered_span {
  background: #eaf3fa;
  color: #4a4a4a;

  &:hover {
    background: #eaf3fa;
    color: #4a4a4a;
  }
}

// начало и конец выбранного промежутка
.CalendarDay__selected {
  background: #eaf3fa;

  .CalendarDay__content {
    background-color: #2887cc;
    color: #fff;
  }

  &:hover {
    background: #eaf3fa;

    .CalendarDay__content {
      background-color: #2887cc;
    }
  }

  // начало выбранного промежутка
  &_start {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
  }

  // конец выбранного промежутка
  &_end,
  // начальная дата выбираемого промежутка, когда окончание еще не выбрано
  // и курсор не наведен на следующую дату
  &.CalendarDay__selected_start_no_selected_end:not(.CalendarDay__selected_start_in_hovered_span) {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }
}

// окончание выбираемого промежутка, когда начало выбрано, а окончание еще нет
.CalendarDay__hovered_span:hover {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}
